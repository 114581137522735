import {version} from '../package.json';

export const config = {
    mantainance: false,
    bundle: "com.your.bundle",
    version: version,
    refreshTokenAdvance: 300, //seconds before expire
    apiKey: "c157c8f0-d5f5-11ec-9483-027654765875",
    baseurl: "",
    defaultLanguage: { code: "it", label: "Italian" },
    languages: [
        { code: "gb", label: "English" },
        { code: "it", label: "Italian" },
    ],
    tab_channel: {
        device: "com.your.bundle/device",
        mainTab: "com.your.bundle/main-tab"
    },
    push_notification: {
        enabled: false,
        apiKey: "xxx",
        config: { // WARNING: Remember to copy this params in /public/firebase-messaging-sw.js
            apiKey: "...",
            authDomain: "...",
            projectId: "...",
            storageBucket: "...",
            messagingSenderId: "...",
            appId: "...",
            measurementId: "..."
        }
    },
    restError: {
        BadRequest: "1000",
        PreconditionFailed: "6000",
        Forbidden: "8000",
    }
};